/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styling/main.sass';
import './src/styling/bulma/bulma.sass';

export const onInitialClientRender = () => {
  // $('.lazy').Lazy();
  //
  // let $win = $(window);
  // let $body_m = $('body');
  //
  // // Get Window Width
  // function winwidth() {
  //   return $win.width();
  // }
  // let wwCurrent = winwidth();
  // $win.on('resize', function() {
  //   wwCurrent = winwidth();
  // });
  //
  //
  // // Sticky
  //
  // // $win.scroll(function() {
  // //   let $is_sticky = $('.is-sticky');
  // //   //let $navm = $('.navbar').offset();
  // //   //debugger;
  // //   let $scroll = $win.scrollTop();
  // //   if ($win.width() > 991) {
  // //     if ($scroll > 40) {
  // //       if (!$is_sticky.hasClass('has-fixed')) {
  // //         $is_sticky.addClass('has-fixed');
  // //       }
  // //     } else {
  // //       if ($is_sticky.hasClass('has-fixed')) {
  // //         $is_sticky.removeClass('has-fixed');
  // //       }
  // //     }
  // //   } else {
  // //     if ($is_sticky.hasClass('has-fixed')) {
  // //       $is_sticky.removeClass('has-fixed');
  // //     }
  // //   }
  // // });
  //
  // // Touch Class
  // if (!('ontouchstart' in document.documentElement)) {
  //   $body_m.addClass('no-touch');
  // }
  //
  // // On Scroll Animation
  // // let $aniKey = $('.animated');
  // // if ($().waypoint && $aniKey.length > 0) {
  // //   $win.on('load', function() {
  // //     $aniKey.each(function() {
  // //       let aniWay = $(this),
  // //         typ = aniWay.data('animate'),
  // //         dur = aniWay.data('duration'),
  // //         dly = aniWay.data('delay');
  // //       aniWay.waypoint(
  // //         function() {
  // //           aniWay.addClass('animated ' + typ).css('visibility', 'visible');
  // //           if (dur) {
  // //             aniWay.css('animation-duration', dur + 's');
  // //           }
  // //           if (dly) {
  // //             aniWay.css('animation-delay', dly + 's');
  // //           }
  // //         },
  // //         { offset: '93%' }
  // //       );
  // //     });
  // //   });
  // // }
  //
  // // Preloader
  // var $preload = $('#preloader'),
  //   $loader = $('#kube');
  // if ($preload.length > 0) {
  //   $win.on('load', function() {
  //     $loader.fadeOut(300);
  //     $body_m.addClass('loaded');
  //     $preload.delay(700).fadeOut(300);
  //   });
  // }
  //
  // if ($('.page-home').length > 0) {
  //   let words = $('.animate-rotate');
  //   let idx = 0;
  //   let animateWords = words => {
  //     let $current = $(words[idx]);
  //     let $next = $(words[idx + 1 === words.length ? 0 : idx + 1]);
  //     //$prev.hide();
  //     //$prev.removeClass('animated bounceInRight');
  //
  //     $current.removeClass('animated bounceInRight');
  //     $current.addClass('animated bounceOutLeft');
  //     window.setTimeout(() => {
  //       $current.hide();
  //       $next.toggle();
  //       $next.removeClass('animated bounceInRight bounceOutLeft');
  //       $next.addClass('animated bounceInRight');
  //       idx = idx === 3 ? 0 : idx + 1;
  //     }, 400);
  //   };
  //   setTimeout(() => {
  //     animateWords(words);
  //     window.setInterval(() => {
  //       animateWords(words);
  //     }, 3500);
  //   }, 500);
  // }
};

// if ($('.page-home').length > 0) {
//   let words = $('.animate-rotate');
//   words.hide();
//   let idx = 0;
//   let animateWords = words => {
//     let $word = $(words[idx]);
//     let $prev = $(words[idx === 0 ? 3 : idx - 1]);
//     //$prev.hide();
//     //$prev.removeClass('animated bounceInRight');
//
//     $prev.removeClass('animated bounceInRight');
//     $prev.addClass('animated bounceOutLeft');
//     window.setTimeout(() => {
//       $prev.hide();
//       $word.toggle();
//       $word.removeClass('animated bounceInRight bounceOutLeft');
//       $word.addClass('animated bounceInRight');
//       idx = idx === 3 ? 0 : idx + 1;
//     }, 400);
//   };
//   animateWords(words);
//   window.setInterval(() => {
//     animateWords(words);
//   }, 2500);
// }
